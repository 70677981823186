import React from 'react';
import './Footer.scss';




const Footer = () => {

    const getCurrentYear = () => {
        return new Date().getFullYear();
    };

    return (
            <div className = "footer text-center">
                <p>{getCurrentYear()} &copy; Family Pharm</p>
            </div>
    )
}

export default Footer;