import React from 'react';
import './AboutUs.scss';
import data from '../../Data/Data.json';
import aboutUsImg from '../../Images/about-us/about-us.png';



const AboutUs = () => {

    return (
        <div id="section1" className="aboutUs">
            <div className="row">
                <div className="col-md-6 order-2 order-md-1">
                    <h1 className="title">{data.aboutUs.title}</h1>
                    <p className="mb-5">{data.aboutUs.desc}</p>
                </div>
                <div className="col-md-6 order-1 order-md-2">
                    <div className="imgContainer">
                        <img className="aboutUsImg" src={aboutUsImg} alt="Family Pharm" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutUs;