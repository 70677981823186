import React from 'react';
import './Nav.scss';
import BgImg from '../../Images/nav-bg/wave.svg';
import Logo from '../../Images/Logo/logo.svg';
import $ from 'jquery';


const Nav = () => {

    $(document).ready(function () {
        $(document).click(function (event) {
            var clickover = $(event.target);
            var _opened = $(".navbar-collapse").hasClass("show");
            if (_opened === true && !clickover.hasClass("navbar-toggler")) {
                $(".navbar-toggler").click();
            }
        });
    });

    $(document).on('click', 'a[href^="#"]', function (event) {
        event.preventDefault();
    
        $('html, body').animate({
            scrollTop: $($.attr(this, 'href')).offset().top
        }, 500);
    });

    return (
        <div className="navigation-bg" style={{backgroundImage: `url(${BgImg})`}}>
            <div className="container container-nav">
                <nav className="navbar navbar-expand-lg navbar-light bg-transparent">
                    <a className="navbar-brand" href="/"><img className = "w-100" src = {Logo} alt = "Logo"/></a>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
                        <ul className="navbar-nav bg-light">
                            <li className="nav-item">
                                <a className="nav-link" href="#section1">O nama <span className="sr-only">(current)</span></a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#section2">Lokacije</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#section3">Galerija</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#section4">Posao i karijera</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#section5">Kontakt</a>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        </div>
    )
}

export default Nav;