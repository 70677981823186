import React from 'react';
import './OpenAllDay.scss';
import data from '../../Data/Data.json';



const OpenAllDay = () => {

    return (
        <div className="openAllDay m-70 text-center">
            <h1 className="text-uppercase">{data.openNonStop.title}</h1>
            <a className="text-uppercase openAllDay-link" href={data.openNonStop.url} target="_blank" rel="noreferrer">{data.openNonStop.address}</a>
            <a className="text-uppercase openAllDay-link" href={"tel:" + data.openNonStop.tel}>{data.openNonStop.tel}</a>
            <a className="openAllDay-link" href={"mailto:" + data.openNonStop.mail}>{data.openNonStop.mail}</a>
        </div>
    )
}

export default OpenAllDay;