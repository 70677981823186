import './App.scss';
import Nav from '../src/Component/Nav/Nav';
import OpenAllDay from '../src/Component/OpenAllDay/OpenAllDay';
import AboutUs from './Component/AboutUs/AboutUs';
import Location from '../src/Component/Location/Location';
import Gallery from '../src/Component/Gallery/Gallery';
import Job from './Component/Job/Job';
import Contact from './Component/Contact/Contact';
import $ from 'jquery';
import Footer from './Component/Footer/Footer';

function App() {
  

  $(document).ready(function() {
  
    var btn = $('#button');
  
    $(window).scroll(function() {
      if ($(window).scrollTop() > 300) {
        btn.addClass('show');
      } else {
        btn.removeClass('show');
      }
    });
  
    btn.on('click', function(e) {
      e.preventDefault();
      $('html, body').animate({scrollTop:0}, '300');
    });
  
  });
  


  return (
    <div className="App">
      <div className="container-fluid">
        <div className="row">
          <Nav />
        </div>
        <div className="container">
          <OpenAllDay />
          <AboutUs />
        </div>
        <Location />
        <div className="container">
          <Gallery />
        </div>
        <Job />
        <Contact />
        <Footer />

        <button id="button"></button>
      </div>
    </div>
  );
}

export default App;
