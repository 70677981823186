import img05 from "../../Images/gallery/IMG_0184.JPG";
import img02 from "../../Images/gallery/IMG_0142.JPG";
import img03 from "../../Images/gallery/IMG_0166.JPG";
import img04 from "../../Images/gallery/IMG_0191.JPG";
import img01 from "../../Images/gallery/IMG_0164.JPG";
import img06 from "../../Images/gallery/IMG_0148.JPG";



export const photos = [
    {
      src: img01,
      width: 4,
      height: 3
    },
    {
      src: img02,
      width: 4,
      height: 3
    },
    {
      src: img03,
      width: 4,
      height: 3
    },
    {
      src: img04,
      width: 4,
      height: 3
    },
    {
      src: img05,
      width: 4,
      height: 3
    },
    {
      src: img06,
      width: 4,
      height: 3
    }
  ];