import React from 'react';
import './Contact.scss';
import data from '../../Data/Data.json';
import contactImg from '../../Images/contact/img-contact.png';



const Contact = () => {

    return (
        <div id="section5" className="row contact-content">
            <div className="container">
                    <div className="row">
                        <div className="col-md-6 mt-5">
                            <h1 className="title">{data.contact.title}</h1>
                            <p className="mb-5">{data.contact.desc}</p>
                            <p><a href = {data.contact.url} target="_blank" rel="noopener noreferrer">{data.contact.address}</a></p>
                            <p><a href = {"tel:" + data.contact.phone}>{data.contact.phone}</a></p>
                            <p><a className="contact-mail" href = {"mailto:" + data.mailAddress}>{data.mailAddress}</a></p>
                        </div>
                        <div className="col-md-6">
                            <p><img className="w-100" src={contactImg} alt="Contact" /></p>
                        </div>
                    </div>
            </div>
        </div>
    )
}

export default Contact;