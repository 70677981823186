import React from 'react';
import './Job.scss';
import data from '../../Data/Data.json';
import imgJobs from '../../Images/job/job.png';




const Job = () => {

    return (
        <div className="row job-content-wrapp m-70">
            <div className="container job-content">
                <div id="section4">
                    <div className="row d-flex align-items-center">
                        <div className="col-md-6">
                            <img className="w-100 job-img" src={imgJobs} alt="Job interview" />
                        </div>
                        <div className="col-md-6 job-desc">
                            <h1 className="title">{data.job.title}</h1>
                            <p>{data.job.desc}</p>
                            <p className="mt-4"><a className="job-mail" href = {"mailto:" + data.mailAddress}>{data.mailAddress}</a></p>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Job;