import React,{Component} from 'react';
import './Location.scss';


class Location extends Component {
    constructor(props) {
 
     class LocationList {
            constructor(url, naziv, adresa, osoba, telefon) {
                this.url = url;
                this.naziv = naziv;
                this.adresa = adresa;
                this.osoba = osoba;
                this.telefon = telefon;
            }
        };
 
     
    var tr1 = new LocationList("https://goo.gl/maps/VNWaXcu3EvwQxQiY7","Family Pharm 01","Obrenovačka 2b, Novi Beograd"," Milica Manojlović dipl.farmaceut","064-11-39-704" );
    var tr2 = new LocationList("https://goo.gl/maps/4ES5nA8GzzZR4tWp7","Family Pharm 02","Pop Lukina 1, Loznica","Vesna Jakovljević dipl.farmaceut","064-879-65-03" );
    var tr3 = new LocationList("https://goo.gl/maps/yU77hRikHcxPeNXk7","Family Pharm 03","Vlade Zečevića 62, Krupanj","Jelena Jovanović farm.tehničar","064-879-65-04" );
    var tr4 = new LocationList("https://goo.gl/maps/136xPHY2KC7eSv8WA","Family Pharm 04","10.oktobar 7, Beograd"," Tamara Nikolić farm.tehničar","064-879-65-00" );
    var tr5a = new LocationList("https://goo.gl/maps/ud3qwE6VBAuFshYL9","Family Pharm 05a","Surčinska 225, Novi Beograd","Miljana Ristanović tehničar","064-821-69-71" );
    var tr6 = new LocationList("https://goo.gl/maps/2EA7QEtZaRCWkoCY6","Family Pharm 06","Vojvode Stepe 240, Beograd","Zorica Brenjo dipl.farmaceut","064-879-65-07" );
    var tr7 = new LocationList("https://goo.gl/maps/LGDt6CDTBxoqnTEj9","Family Pharm 07","Vojvode Putnika 4, Loznica","Suzana Vasiljević dipl.farmaceut","064-879-65-09" );
    var tr8 = new LocationList("https://goo.gl/maps/rQXfLVpEx58Sfxqg7","Family Pharm 08","Kruševačka 40b, Beograd","Zorica Andrić dipl.farmaceut","064-801-56-14" );
    var tr9a = new LocationList("https://goo.gl/maps/62ETMKEfkvxnEhw16","Family Pharm 09a","Vlade Zečevića 61, Krupanj","Vesna Jurišić tehničar","064-821-69-70" );
    var tr10a = new LocationList("https://goo.gl/maps/aWc9coAQvhGFfd227","Family Pharm 10a","Miloša Velikog 5, Gornji Milanovac","Jelena Jovanović tehničar","064-821-69-73" );
        //  var tr11 = new LocationList("Family Pharm 11", );
    var tr12 = new LocationList("https://goo.gl/maps/V1Ew8PSUEDrMFPme7","Family Pharm 12","Pašićeva 9, Loznica","Smilja Vasiljević farm.tehničar","064-879-65-84" );
    var tr13 = new LocationList("https://goo.gl/maps/Qgsea63n4SBcW4TM8","Family Pharm 13","Vojvode Mišića 59, Ljubovija","Milica Stajić dipl. farmaceut","064-879-65-06" );
    //  var tr14 = new LocationList("Family Pharm 14", );
    var tr15 = new LocationList("https://goo.gl/maps/J6LSYP1446uXoooK9","Family Pharm 15","Šturova 42, Kovačica","Jelena Sekulić farm.tehničar","064-879-65-65" );
    var tr16 = new LocationList("https://goo.gl/maps/HUNxV3h7tHeNrrzD7","Family Pharm 16","Servo Mihalja 37, Debeljača","Mirjana Vidac dipl.farmaceut","064-879-62-21" );
    var tr17 = new LocationList("https://goo.gl/maps/3tVFuBk2wDAkS1CN9","Family Pharm 17","Trg oslobođenja 2, Crepaja","Svetlana Stojkov Vojinov farm.tehničar","064-879-62-22" );
    var tr18 = new LocationList("https://goo.gl/maps/Dz9DxWy5N2gGtfBJ7","Family Pharm 18","Trg oslobođenja 26, Padina","Evka Haravan farm.tehničar","064-879-65-47" );
    var tr19 = new LocationList("https://goo.gl/maps/SafdDiixvSHfAztm9","Family Pharm 19","Maršala Tita 30, Samoš","Slavica Popadić farm.tehničar","064-879-62-23" );
    //  var tr20 = new LocationList("Family Pharm 20", );
    var tr21 = new LocationList("https://goo.gl/maps/Aaq86LnDJrbWjUJp6","Family Pharm 21","Tudora Vladimireskua 178, Uzdin","Violeta Motorozesku farm.tehničar","064-879-62-24" );
    var tr22 = new LocationList("https://goo.gl/maps/3Yc7L8WKuL29zkET7","Family Pharm 22","Vojvode Milana 37, Gornji Milanovac","Svetlana Dmitrov dipl.farmaceut","064-879-62-25" );
    var tr23 = new LocationList("https://goo.gl/maps/2wzXhqmpPei9hsbC7","Family Pharm 23"," Dom zdravlja, Tihomira Matijevića 1, Gornji Milanovac","Marija Jovanović dipl.farmaceut","064-879-65-48" );
    var tr24 = new LocationList("https://goo.gl/maps/71ce9fKEXnJAqYP87","Family Pharm 24","Pranjani bb, Pranjani","Snežana Vasiljević farm.tehničar","064-895-55-96" );
    var tr25 = new LocationList("https://goo.gl/maps/gHXjkfnFFXHpA7Hr9","Family Pharm 25","Karađorđeva bb, Rudnik","Marina Marković farm.tehničar","064-895-55-97" );
 
     
       super(props) //since we are extending class Table so we have to use super in order to override Component class constructor
       // eslint-disable-next-line
       this.state = { //state is by default an object
          loca: [tr1, tr2, tr3, tr4, tr5a, tr6, tr7, tr8, tr9a, tr10a, tr12, tr13, tr15, tr16, tr17, tr18, tr19, tr21, tr22, tr23, tr24, tr25]
       }
    }
 
     renderTableData() {
         return this.state.loca.map((locUrl, index) => {
            const { url, naziv, adresa, osoba, telefon } = locUrl //destructuring
            return (
                
                <div key={index} className="location-card col-sm-12 col-md-6 col-xl-4" >
                    <div className="card-wrapp">
                        <div className="card-body">
                            <h4 className="card-title mb-4 text-uppercase">{naziv}</h4>
                            <p className="card-text mb-4">{osoba}</p>
                            <a href={url} title={adresa} target="_blank" rel="noreferrer"><p className="card-text card-address mb-4">{adresa}</p></a>
                            <p className="card-text mt-3"><a className = "telefon mt-4" href = {`tel:${telefon}`}>{telefon}</a></p>
                        </div>
                    </div>
                </div>
            )
         })
     }

    render(){

    return (
        <div className="row location-wrapp m-70">
            <div className="container">
                <div id="section2">
                    <div className = "location-content">
                        <h1 className = "title">Lokacije</h1>
                        <div className="row">
                            {this.renderTableData()}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        )
    }
}
export default Location;